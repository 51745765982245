<template>
    <div class="sim-product-list">
        <slot />
    </div>
</template>

<script lang="ts" setup>

</script>

<style lang="scss" scoped>

.sim-product-list {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));

    @include more-than-custom(632) {
        grid-template-columns: repeat(auto-fill, minmax(17rem, 1fr));
    }
}

</style>
